import { Box, Divider } from '@chakra-ui/react'
import { ComboBox, SELECTION_MODE } from 'components/Dropdown/ComboBox/ComboBox'
import { useMultiSelectLabel } from 'components/Filters/useMultiSelectLabel'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { useMetricGroups } from 'features/reports/components/ReportSidebar/hooks/useMetricGroups'
import { ORGANIZATION_ID } from 'features/reports/consts'
import { useDimensionsState } from 'features/reports/hooks/useDimensionsState'
import {
  useMetricsState,
  useSetMetricsState,
} from 'features/reports/hooks/useMetricsState'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import {
  getMetricKeyWithoutAttribution,
  useNormalizedMetrics,
} from 'graphql/statistics/useMetrics'
import { useUserTeams } from 'graphql/teams/useUserTeams'
import { isEqual } from 'lodash-es'
import { useMemo, useState } from 'react'
import { ReportDetailRowMenuButton } from '../ReportDetailsRow/ReportDetailsRow'
import { TeamToggle } from '../shared/TeamToggle'
import { useFinalMetrics } from './useFinalMetrics'

const infoDetails = {
  tooltipText:
    'A metric is a quantitative measurement expressed by a numeric value. It is typically either a currency value or a percentage value. How the value is calculated depends on the specific metric.',
  link: 'https://dema.ai', // TODO change the links once it has been decided by where it should go
}

export const MetricsSection = () => {
  const normalizedMetrics = useNormalizedMetrics()
  const { uniqueMetrics } = useMetricsState()
  const uniqueMetricKeys = useMemo(
    () => uniqueMetrics.map(getMetricKeyWithoutAttribution),
    [uniqueMetrics],
  )
  const setMetrics = useSetMetricsState()
  const { dimensions } = useDimensionsState()
  const [trackEvent] = useTrackEvent()
  const { userTeams } = useUserTeams()
  const [selectedTeam, setSelectedTeam] = useState(ORGANIZATION_ID)
  const teamsWithPreferredData = useMemo(
    () => userTeams.filter((team) => team.preferredMetrics.length > 0),
    [userTeams],
  )
  const hasTeamsWithPreferredData = teamsWithPreferredData.length > 0

  const filteredMetrics = useMemo(() => {
    const allMetrics = Object.values(normalizedMetrics)

    if (selectedTeam === ORGANIZATION_ID || !hasTeamsWithPreferredData) {
      return allMetrics
    }

    const preferredMetrics = teamsWithPreferredData.find(
      (team) => team.id === selectedTeam,
    )?.preferredMetrics

    return allMetrics.filter((metric) => {
      return preferredMetrics?.includes(metric.groupKey)
    })
  }, [
    normalizedMetrics,
    selectedTeam,
    teamsWithPreferredData,
    hasTeamsWithPreferredData,
  ])

  const { getFinalMetrics } = useFinalMetrics()

  const metricGroups = useMetricGroups({ dimensions, filteredMetrics })

  const setSelectedMetrics = (newMetricIds: string[]) => {
    if (newMetricIds.length === 0) {
      return
    }

    const selectedUniqueMetricsIds = [...uniqueMetricKeys].sort()
    const sortedNewMetricIds = [...newMetricIds].sort()

    // there where no changes
    if (isEqual(selectedUniqueMetricsIds, sortedNewMetricIds)) {
      return
    }

    const populatedMetrics = newMetricIds.map((id) => normalizedMetrics[id])
    const finalMetrics = getFinalMetrics(populatedMetrics)
    const finalMetricIds = finalMetrics.map((item) => item.key).sort()

    trackEvent({
      eventName: 'Report Metric Changed',
      eventProperties: {
        allMetrics: finalMetricIds,
        newMetrics: finalMetricIds.filter(
          (metric) => !selectedUniqueMetricsIds.find((m) => m === metric),
        ),
      },
    })

    setMetrics(finalMetricIds)
  }

  const label = useMultiSelectLabel({
    selectedIds: uniqueMetricKeys,
    normalizedOptions: normalizedMetrics,
    defaultEmptyLabel: 'Select metrics',
  })

  return (
    // Typescript fails to infer the type of the item in itemWrapper function so we need to specify it directly
    <ComboBox<(typeof metricGroups)[number]['items'][number]>
      selectionMode={SELECTION_MODE.MULTIPLE}
      searchPlaceholder="Search metrics"
      buttonText={label}
      customHeader={
        hasTeamsWithPreferredData ? (
          <>
            <Box px={4} py={2}>
              <TeamToggle
                teams={teamsWithPreferredData}
                allTitle="All metrics"
                selected={selectedTeam}
                setSelected={setSelectedTeam}
              />
            </Box>
            <Divider />
          </>
        ) : undefined
      }
      customMenuButton={
        <ReportDetailRowMenuButton
          title="Metrics"
          label={label}
          iconName="BarChartIcon"
          infoDetails={infoDetails}
        />
      }
      options={metricGroups}
      selected={uniqueMetricKeys}
      setSelected={setSelectedMetrics}
      isFullHeight
      matchWidth={false}
      listProps={{ minW: '320px' }}
      itemWrapper={({ children, item }) => {
        return (
          <Tooltip
            key={`${item.name}`}
            label={`${item.description}`}
            closeOnScroll
            placement="left-start"
            closeOnClick={false}
            w="100%"
          >
            {children}
          </Tooltip>
        )
      }}
    />
  )
}
