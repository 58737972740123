import { type ReportResult } from 'graphql/reports/types'
import {
  type NormalizedStatistic,
  type TimeUnit,
} from 'graphql/statistics/types'

import { useNormalizedMetrics } from 'graphql/statistics/useMetrics'
import { useStatistics } from 'graphql/statistics/useStatistics'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { useMemo } from 'react'
import { CHART_TYPE_ID } from 'utils/chart/chartTypes'
import { compareColor, isTimeUnit } from 'utils/chart/constants'

type UseChartAnalyticsResponse = {
  chartData: NormalizedStatistic[]
  loading: boolean
}

const getDimensionsForChart = (
  dimensions: string[],
  xAxis: string | undefined,
  isXAxisTimeUnit: boolean,
  isXAxisMetric: boolean,
  color?: string,
  isScatterPlot?: boolean,
) => {
  const initDimensions = isScatterPlot ? [...dimensions] : []
  const result = new Set(initDimensions)

  if (xAxis && !isXAxisTimeUnit && !isXAxisMetric) {
    // X axis is a dimension
    result.add(xAxis)
  }

  if (color && color !== compareColor.id) {
    // Color dimension is selected
    result.add(color)
  }

  if (result.size === 0) {
    // Aggregate data if any time X axis is selected and none of the above apply
    return isXAxisTimeUnit ? [] : undefined
  }

  return Array.from(result)
}

const getMetricsForChart = (
  seriesKeys: string[],
  xAxis: string,
  isXAxisMetric: boolean,
  metricFilterKeys: string[],
) => {
  const metrics = new Set([...seriesKeys, ...metricFilterKeys])

  if (isXAxisMetric) {
    metrics.add(xAxis)
  }

  return Array.from(metrics).filter((m) => !!m)
}

export const useChartAnalyticsFromReport = ({
  analyticsConfig: {
    filters,
    dimensions,
    chart,
    startDate,
    endDate,
    compareStartDate,
    compareEndDate,
  },
}: ReportResult): UseChartAnalyticsResponse => {
  const { timezone, frontendIds = [], currency } = useMerchantInfo()
  const normalizedMetrics = useNormalizedMetrics()
  const { xAxis, color: chartGroup, series } = chart ?? {}

  const isXAxisTimeUnit = isTimeUnit(xAxis)
  const timeUnit: TimeUnit = isXAxisTimeUnit ? xAxis : 'total'
  const isXAxisMetric = !isXAxisTimeUnit && !!normalizedMetrics[xAxis]

  const metricFilterKeys = useMemo(
    () => Object.keys(filters).filter((key) => !!normalizedMetrics[key]),
    [filters, normalizedMetrics],
  )
  const isScatterPlot =
    series.length === 1 && series[0].type === CHART_TYPE_ID.SCATTER

  const chartDimensions = getDimensionsForChart(
    dimensions,
    xAxis,
    isXAxisTimeUnit,
    isXAxisMetric,
    chartGroup || undefined,
    isScatterPlot,
  )
  const metrics = getMetricsForChart(
    series.map(({ key }) => key),
    xAxis,
    isXAxisMetric,
    metricFilterKeys,
  )
  const shouldFetch =
    Boolean(timezone) &&
    Boolean(currency) &&
    Boolean(startDate) &&
    Boolean(endDate) &&
    frontendIds.length > 0 &&
    metrics.length > 0 &&
    Object.keys(normalizedMetrics).length > 0 &&
    xAxis &&
    series.filter(({ key }) => !!key).length > 0 // xAxis and yAxis can be undefined when no metric is selected.

  const { query: chartDataQuery, statistics: chartData } = useStatistics({
    skip: !shouldFetch,
    variables: {
      statisticsParams: {
        frontendIds,
        startDate,
        endDate,
        dimensions,
        metrics,
        timeUnit,
        filter: filters,
        compareStartDate: compareStartDate,
        compareEndDate: compareEndDate,
        aggregateDimensions: chartDimensions,
      },
    },
  })

  return {
    chartData,
    loading: chartDataQuery.loading,
  }
}
