import { type ReportState } from 'features/reports/atoms/reportViewStateAtom'
import {
  reportIdAtom,
  useSetReportStateAtom,
} from 'features/reports/hooks/useReportState'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { getIsTemplateReport } from 'graphql/reports/utils'
import { useAtomValue } from 'jotai'
import { useCallback } from 'react'

export const useSelectReport = () => {
  const currentReportId = useAtomValue(reportIdAtom)
  const setReportAtom = useSetReportStateAtom()
  const [trackEvent] = useTrackEvent()

  return useCallback(
    (report: ReportState) => {
      if (!report || !report.id || report.id === currentReportId) {
        return
      }

      const isTemplateReport = getIsTemplateReport(report.owner)

      trackEvent({
        eventName: 'Report Picked',
        eventProperties: {
          isTemplateReport: !!isTemplateReport,
          reportId: report.id,
          reportName: report.name,
        },
      })
      setReportAtom(report)
    },
    [currentReportId, trackEvent, setReportAtom],
  )
}
