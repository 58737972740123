import { useAnalyticsConfigById } from 'graphql/reports/useAnalyticsConfigById'
import { ANALYTICS_CONFIG_SEARCH_PARAM_KEY } from 'graphql/reports/useCreateAnalyticsConfig'
import { useReportById } from 'graphql/reports/useReportById'
import { useSetAtom } from 'jotai'
import { useEffect } from 'react'
import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import {
  analyticsConfigAtom,
  reportStateAtom,
  ReportViewStatus,
} from '../atoms/reportViewStateAtom'
import { demaReports } from '../demaReports'
import { useIsNewReportFlow } from '../hooks/useIsNewReportFlow'
import { useNewReportState } from '../hooks/useReportState'
import { useSetReportStatus } from '../hooks/useReportStatus'

/**
 * `ReportStateSync` creates a unidirectional sync from the backend to the store.
 * This component fetches data from the backend and overrides the store with the fetched data.
 * If reportId is a dema report, it sets that report to the state in the store. This is needed when refreshing the page.
 * If user is on new report page, and there is no report state set, it sets the report state to the new report state. This is needed when refreshing the page.
 * Having a special component to sync the state makes sure that only the necessary components are rendered. This is important for performance reasons.
 */
export const ReportStateSync = () => {
  const { id: reportId } = useParams()
  const [searchParams] = useSearchParams()
  const analyticsConfigId = searchParams.get(ANALYTICS_CONFIG_SEARCH_PARAM_KEY)

  const {
    report,
    query: { loading: isReportQueryLoading },
  } = useReportById(reportId)
  const {
    analyticsConfig,
    query: { loading: isAnalyticsQueryLoading },
  } = useAnalyticsConfigById(analyticsConfigId)
  const setReportState = useSetAtom(reportStateAtom)
  const setAnalyticsConfigState = useSetAtom(analyticsConfigAtom)
  const setReportStatus = useSetReportStatus()
  const isDemaReport = demaReports.find((report) => report.id === reportId)
  const isNewReportFlow = useIsNewReportFlow()
  const newReportState = useNewReportState()

  useEffect(() => {
    if (!isAnalyticsQueryLoading) {
      // Do not set state during transient states
      setAnalyticsConfigState(analyticsConfig)
    }
  }, [analyticsConfig, isAnalyticsQueryLoading, setAnalyticsConfigState])

  useEffect(() => {
    if (isDemaReport) {
      setReportState(isDemaReport)

      return
    }
    if (isNewReportFlow) {
      setReportState((prev) => {
        // The previous state will be undefined when refreshing the page. In that case, we default to the new state.
        // When navigating inside the app, the state will have been optimistically set with specific values so we don't override it.
        return prev ?? newReportState
      })

      return
    }
    if (!isReportQueryLoading) {
      setReportState(report)
    }
  }, [
    isReportQueryLoading,
    report,
    setReportState,
    isDemaReport,
    isNewReportFlow,
    newReportState,
  ])

  useEffect(() => {
    if (!isReportQueryLoading && !isAnalyticsQueryLoading) {
      const hasNotFoundReport = reportId && !report
      const hasNotFoundAnalytics = analyticsConfigId && !analyticsConfig
      const missingReportId = !reportId
      const missingAnalyticsConfigId = !analyticsConfigId

      if (
        !isDemaReport &&
        ((hasNotFoundReport && hasNotFoundAnalytics) ||
          (missingReportId && hasNotFoundAnalytics) ||
          (missingAnalyticsConfigId && hasNotFoundReport))
      ) {
        setReportStatus(ReportViewStatus.NotFound)

        return
      }

      setReportStatus(ReportViewStatus.InSync)
    }
  }, [
    analyticsConfigId,
    reportId,
    report,
    analyticsConfig,
    isReportQueryLoading,
    isAnalyticsQueryLoading,
    setReportStatus,
    isDemaReport,
  ])

  return null
}
