import { demaReports } from '../demaReports'
import {
  useNewReportState,
  useSetReportStateAtom,
} from '../hooks/useReportState'
import { REPORT_PAGE_TITLES } from '../routePages'
import { ReportListTable } from './shared/ReportListTable'

export const DemaReportsView = () => {
  const setReportState = useSetReportStateAtom()
  const newReportState = useNewReportState()

  return (
    <ReportListTable
      pageTitle={REPORT_PAGE_TITLES.DEMA}
      reports={demaReports}
      addReportCallback={() => {
        setReportState(newReportState)
      }}
    />
  )
}
