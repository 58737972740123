import { Box, Flex } from '@chakra-ui/react'
import { type SortDirection } from 'ag-grid-community'
import { Typography } from 'components/Typography'
import {
  useAnalyticsTableState,
  useSetTableState,
} from 'features/reports/hooks/useAnalyticsTableState'
import { forwardRef } from 'react'
import { CellWrapper } from '../cell/CellWrapper'
import { type AnalyticsTableHeaderProps } from '../types'
import { HeaderMenuWrapper } from './HeaderMenuWrapper'
import { HeaderText } from './HeaderText'
import { HeaderTotalSection } from './HeaderTotalSection'

export const AnalyticsTableHeader = forwardRef(
  (props: AnalyticsTableHeaderProps, ref) => {
    const columnId = props.column?.getColId()
    const isLoading = props.isLoading
    const state = useAnalyticsTableState()
    const { setTableSorting, setTableColumnPinning } = useSetTableState()

    const updateSortState = (sort: SortDirection | undefined) => {
      setTableSorting(columnId, sort || null)
    }

    const onHeaderClick = () => {
      props.progressSort()
      const sort = props.column.getSort()

      updateSortState(sort)
    }

    const setSortOrder = (sort: SortDirection) => {
      props.setSort(sort)

      updateSortState(sort)
    }

    const toggleFreezeColumn = () => {
      const isPinned = props.column.isPinned()

      props.api.setColumnsPinned([columnId], isPinned ? null : 'left')

      setTableColumnPinning(columnId, !isPinned)
    }

    const sortValue = state.find(({ id, sort }) => !!sort && id === columnId)

    return (
      <CellWrapper gap={2} justifyContent="space-between" py={3} ref={ref}>
        <Flex
          justifyContent="space-between"
          alignItems="flex-start"
          flexDir={props.metric ? 'row-reverse' : 'row'}
          _hover={{
            '& .icon': {
              visibility: 'visible',
            },
          }}
        >
          <Box onClick={onHeaderClick} as="button">
            <HeaderText
              isMetric={!!props.metric}
              showIcon
              sortDirection={sortValue?.sort}
              description={
                props.dimension?.description || props.metric?.description
              }
            >
              {props.displayName}
            </HeaderText>
          </Box>
          <HeaderMenuWrapper
            setSortOrder={setSortOrder}
            columnId={columnId}
            displayName={props.displayName}
            isMetric={!!props.metric}
            isFrozen={props.column.isPinned()}
            toggleFreezeState={toggleFreezeColumn}
          />
        </Flex>
        <Flex flexDir="column" gap={1}>
          {props.attributionLabel ? (
            <Typography
              textAlign="end"
              noOfLines={1}
              color="grey.900"
              whiteSpace="nowrap"
              display="block"
              fontSize="xs"
            >
              {props.attributionLabel} model
            </Typography>
          ) : null}

          <HeaderTotalSection
            isLoading={isLoading}
            metric={props.metric}
            isCompare={props.isCompare}
            aggregatedData={props.aggregatedData}
          />
        </Flex>
      </CellWrapper>
    )
  },
)

AnalyticsTableHeader.displayName = 'AnalyticsTableHeader'
