import type {
  InitializeAppQuery,
  MerchantSite,
} from 'generated/graphql/graphql'
import { atom, useAtomValue } from 'jotai'

type GraphMerchant = NonNullable<InitializeAppQuery['viewer']>['merchant']

type NormalizedSites = Record<string, Partial<MerchantSite>>

const mapDataToMerchantInfo = (data: GraphMerchant | null) => {
  const { sites: merchantSites, timezone, currency } = data ?? {}
  const frontendIds = merchantSites?.map(({ frontendId }) => frontendId)
  const firstSite = merchantSites?.[0]
  const normalizedSites = merchantSites?.reduce<NormalizedSites>(
    (acc, next) => {
      acc[next.frontendId] = next

      return acc
    },
    {},
  )

  return {
    currency,
    frontendId: firstSite?.frontendId,
    merchantId: data?.id ?? '',
    normalizedSites,
    frontendIds,
    sites: merchantSites,
    timezone: timezone ?? 'UTC',
  }
}

export const merchantInfoAtom = atom((get) => {
  const data = get(merchantQueryAtom)

  return mapDataToMerchantInfo(data)
})

export const merchantQueryAtom = atom<GraphMerchant | null>(null)

// To use in components
export const useMerchantInfo = () => {
  return useAtomValue(merchantInfoAtom)
}
