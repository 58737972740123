import { useOrganization } from '@clerk/clerk-react'
import { useMerchantReports } from 'graphql/reports/useMerchantReports'
import { getMerchantVisibilityFromOrganization } from 'graphql/reports/utils'
import {
  useNewReportState,
  useSetReportStateAtom,
} from '../hooks/useReportState'
import { ReportListTable } from './shared/ReportListTable'

export const OrganisationReportsView = () => {
  const { merchantReports, query } = useMerchantReports()
  const { organization } = useOrganization()
  const setReportState = useSetReportStateAtom()
  const newReportState = useNewReportState()

  return (
    <ReportListTable
      pageTitle={organization?.name}
      reports={merchantReports}
      isLoading={query.loading}
      addReportCallback={() => {
        setReportState({
          ...newReportState,
          visibility: getMerchantVisibilityFromOrganization(organization),
        })
      }}
    />
  )
}
