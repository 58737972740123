import { Box, Divider } from '@chakra-ui/react'
import { ComboBox, SELECTION_MODE } from 'components/Dropdown/ComboBox/ComboBox'
import { useMultiSelectLabel } from 'components/Filters/useMultiSelectLabel'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { ORGANIZATION_ID } from 'features/reports/consts'
import {
  useDimensionsState,
  useSetDimensionsState,
} from 'features/reports/hooks/useDimensionsState'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { useNormalizedDimensions } from 'graphql/statistics/useDimensions'
import { useUserTeams } from 'graphql/teams/useUserTeams'
import { isEqual } from 'lodash-es'
import { useMemo, useState } from 'react'
import { useDimensionGroups } from '../../ReportSidebar/hooks/useDimensionGroups'
import { ReportDetailRowMenuButton } from './ReportDetailsRow/ReportDetailsRow'
import { TeamToggle } from './shared/TeamToggle'

const infoDetails = {
  tooltipText:
    'A dimension is a property of your data. Its usually text as opposed to a numerical value. An example of a dimension is Channel, which specifies the channel that the traffic to your web site comes from.',
  link: 'https://dema.ai',
}

export const DimensionsSection = () => {
  const normalizedDimensions = useNormalizedDimensions()
  const { dimensionKeys } = useDimensionsState()
  const setDimensions = useSetDimensionsState()
  const [trackEvent] = useTrackEvent()
  const { userTeams } = useUserTeams()
  const [selectedTeam, setSelectedTeam] = useState(ORGANIZATION_ID)
  const teamsWithPreferredData = useMemo(
    () => userTeams.filter((team) => team.preferredDimensions.length > 0),
    [userTeams],
  )

  const hasTeamsWithPreferredData = teamsWithPreferredData.length > 0

  const filteredDimensions = useMemo(() => {
    const allDimensions = Object.values(normalizedDimensions)

    if (selectedTeam === ORGANIZATION_ID || !hasTeamsWithPreferredData) {
      return allDimensions
    }

    const preferredDimensions = teamsWithPreferredData.find(
      (team) => team.id === selectedTeam,
    )?.preferredDimensions

    return allDimensions.filter((dim) => {
      return preferredDimensions?.includes(dim.id)
    })
  }, [
    normalizedDimensions,
    selectedTeam,
    teamsWithPreferredData,
    hasTeamsWithPreferredData,
  ])

  const dimensionGroups = useDimensionGroups({ dimensions: filteredDimensions })

  const setSelectedDimensions = (newDimensionIds: string[]) => {
    if (newDimensionIds.length === 0) {
      return
    }

    const selectedDimensionsIds = [...dimensionKeys].sort()
    const sortedNewDimensionIds = [...newDimensionIds].sort()

    // there where no changes
    if (isEqual(selectedDimensionsIds, sortedNewDimensionIds)) {
      return
    }

    trackEvent({
      eventName: 'Report Dimension Changed',
      eventProperties: {
        allDimensions: sortedNewDimensionIds,
        newDimensions: sortedNewDimensionIds.filter(
          (dimension) => !selectedDimensionsIds.find((d) => d === dimension),
        ),
      },
    })

    setDimensions(sortedNewDimensionIds)
  }

  const label = useMultiSelectLabel({
    selectedIds: dimensionKeys,
    normalizedOptions: normalizedDimensions,
    defaultEmptyLabel: 'Select dimensions',
  })

  return (
    // Typescript fails to infer the type of the item in itemWrapper function so we need to specify it directly
    <ComboBox<(typeof dimensionGroups)[number]['items'][number]>
      selectionMode={SELECTION_MODE.MULTIPLE}
      searchPlaceholder="Search dimensions"
      buttonText={label}
      customHeader={
        hasTeamsWithPreferredData ? (
          <>
            <Box px={4} py={2}>
              <TeamToggle
                teams={teamsWithPreferredData}
                allTitle="All dimensions"
                selected={selectedTeam}
                setSelected={setSelectedTeam}
              />
            </Box>
            <Divider />
          </>
        ) : undefined
      }
      customMenuButton={
        <ReportDetailRowMenuButton
          title="Dimensions"
          label={label}
          iconName="TableIcon"
          infoDetails={infoDetails}
        />
      }
      options={dimensionGroups}
      selected={dimensionKeys}
      setSelected={setSelectedDimensions}
      isFullHeight
      matchWidth={false}
      listProps={{ minW: '320px' }}
      itemWrapper={({ children, item }) => {
        return (
          <Tooltip
            key={`${item.name}`}
            label={`${item.description}`}
            closeOnScroll
            placement="left-start"
            closeOnClick={false}
            w="100%"
          >
            {children}
          </Tooltip>
        )
      }}
    />
  )
}
