import { OrderedList, ListItem, Box } from '@chakra-ui/react'
import { Typography } from 'components/Typography'
import { useEffect, useState } from 'react'
import { CodeSnippet } from '../CodeSnippet'
import pageViewTrackingSnippet from './pageViewSnippet.txt'

export const PageViewTracking = () => {
  const [fetchedSnippet, setFetchedSnippet] = useState('')

  useEffect(() => {
    fetch(pageViewTrackingSnippet)
      .then((r) => r.text())
      .then(setFetchedSnippet)
  }, [])

  return (
    <>
      <Typography fontSize="xl" lineHeight={8} color="grey.900" noOfLines={1}>
        Pageview tag
      </Typography>
      <OrderedList my={4} spacing={2} px={2} maxW={800}>
        <ListItem>
          Log in to your GTM account, click on &quot;Tags&quot; in the left
          sidebar navigation and click the &quot;New&quot; button on top of the
          page.
        </ListItem>
        <ListItem>Name the tag &quot;Dema pageview&quot;.</ListItem>
        <ListItem>
          Click on &quot;Custom HTML Tag&quot; and copy/paste the following code
          into the text field:
        </ListItem>
        <Box>
          <CodeSnippet snippet={fetchedSnippet} language="xml" />
        </Box>
      </OrderedList>
    </>
  )
}
