import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { omit } from 'lodash-es'
import { getStore } from 'shared/store'
import { filtersStateAtom, useSetFiltersState } from './useFiltersState'

export const useRemoveFilter = () => {
  const setFilters = useSetFiltersState()
  const [trackEvent] = useTrackEvent()

  return (filterId: string) => {
    const filters = getStore().get(filtersStateAtom)
    const newFilters = omit(filters, filterId)

    trackEvent({
      eventName: 'Report Filter Removed',
      eventProperties: {
        key: filterId,
      },
    })

    if (Object.keys(newFilters).length > 0) {
      setFilters(newFilters)

      return
    }
    setFilters({})
  }
}
