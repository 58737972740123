import { Flex } from '@chakra-ui/react'
import { getWeek } from 'date-fns'
import { useChartAnalytics } from 'features/reports/hooks/useChartAnalytics'
import { useChartState } from 'features/reports/hooks/useChartState'
import { useMetricsState } from 'features/reports/hooks/useMetricsState'
import Highcharts from 'highcharts'
import HCAccessibility from 'highcharts/modules/accessibility'
import HCBoost from 'highcharts/modules/boost'
import HCExporting from 'highcharts/modules/exporting'
import HCNoDataToDisplay from 'highcharts/modules/no-data-to-display'
import HighchartsReact from 'highcharts-react-official'

import { useEffect, useRef } from 'react'
import { debounce } from 'utils/debounce'
import { useChartOptions } from './useChartOptions'

HCExporting(Highcharts)
HCAccessibility(Highcharts)
HCNoDataToDisplay(Highcharts)
HCBoost(Highcharts)

Highcharts.dateFormats.W = function (timestamp) {
  return String(
    getWeek(new Date(timestamp), {
      weekStartsOn: 1,
      firstWeekContainsDate: 4,
    }),
  )
}

Highcharts.setOptions({
  time: {
    useUTC: false,
  },
})

export const AnalyticsChart = () => {
  const chartRef = useRef<HighchartsReact.RefObject>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const { series } = useChartState()
  const { chartData, loading } = useChartAnalytics()
  const { metrics: selectedMetrics } = useMetricsState()
  const isLoading = selectedMetrics.length !== 0 && loading

  const options = useChartOptions(chartData, isLoading)

  useEffect(() => {
    if (isLoading) {
      chartRef.current?.chart.showLoading()
    } else {
      chartRef.current?.chart.hideLoading()
      chartRef.current?.chart.redraw()
    }
  }, [isLoading, options])

  useEffect(() => {
    chartRef.current?.chart.zoomOut()
  }, [series])

  useEffect(() => {
    if (containerRef.current) {
      const observer = new ResizeObserver(() => {
        debounce(() => {
          chartRef.current?.chart.reflow()
        })()
      })

      observer.observe(containerRef.current)

      return () => observer.disconnect()
    }
  }, [])

  return (
    <Flex
      ref={containerRef}
      w="100%"
      minH="360px"
      maxH="360px"
      mb={4}
      overflow="hidden"
    >
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
        containerProps={{ style: { width: '100%' } }}
      />
    </Flex>
  )
}
