import { type RouteObject } from 'react-router'
import { REPORT_ROUTE_FRAGMENTS } from './routePages'
import { AllReportsView } from './views/AllReportsView'
import { DemaReportsView } from './views/DemaReportsView'
import { NewReportView } from './views/NewReportView/NewReportView'
import { OrganisationReportsView } from './views/OrganisationReportsView'
import { ReportView } from './views/ReportView'
import { TeamReportsView } from './views/TeamReportsView'
import { UserReportsView } from './views/UserReportsView'

export const routes: RouteObject[] = [
  {
    index: true,
    element: <ReportView />,
  },
  {
    children: [
      {
        index: true,
        path: REPORT_ROUTE_FRAGMENTS.USER,
        element: <UserReportsView />,
      },
      {
        path: REPORT_ROUTE_FRAGMENTS.ALL,
        element: <AllReportsView />,
      },
      {
        path: REPORT_ROUTE_FRAGMENTS.ORGANISATION,
        element: <OrganisationReportsView />,
      },
      {
        path: REPORT_ROUTE_FRAGMENTS.DEMA,
        element: <DemaReportsView />,
      },
      {
        path: ':id',
        element: <ReportView />,
      },
    ],
  },
  {
    path: REPORT_ROUTE_FRAGMENTS.TEAMS,
    children: [
      {
        path: ':teamId',
        element: <TeamReportsView />,
      },
    ],
  },
  {
    path: REPORT_ROUTE_FRAGMENTS.NEW,
    element: <NewReportView />,
  },
]
