import type {
  OrganizationMembershipResource,
  UserResource,
  OrganizationResource,
} from '@clerk/types'
import { ORGANIZATION_ID } from 'features/reports/consts'
import {
  type MerchantFieldsFragment,
  type OwnerFieldsFragment,
  type ReportWithOwnerFieldsFragment,
} from 'generated/graphql/graphql'
import { type ReportResult, type ReportVisibility } from './types'

type ReportVisibilityType = 'private' | 'team' | 'organisation'

export const REPORT_VISIBILITY = {
  PRIVATE: 'private',
  TEAM: 'team',
  ORGANISATION: 'organisation',
} satisfies Record<string, ReportVisibilityType>

export const REPORT_VISIBILITY_SORT_ORDER = {
  [REPORT_VISIBILITY.PRIVATE]: 0,
  [REPORT_VISIBILITY.ORGANISATION]: 1,
  [REPORT_VISIBILITY.TEAM]: 2,
} satisfies Record<ReportVisibilityType, number>

export const getReportVisibilityType = (
  visibility?: ReportVisibility[],
): ReportVisibilityType => {
  if (!visibility || visibility.length === 0) return REPORT_VISIBILITY.PRIVATE
  if (getIsMerchantVisibility(visibility[0]))
    return REPORT_VISIBILITY.ORGANISATION

  return REPORT_VISIBILITY.TEAM
}

export const getIsTemplateReport = (owner?: OwnerFieldsFragment) => {
  return !owner || owner.id === ''
}

export const getMerchantVisibilityFromOrganization = (
  organization: OrganizationResource | null | undefined,
): MerchantFieldsFragment[] => {
  return [
    {
      id: String(organization?.publicMetadata.dema_id),
      name: String(organization?.name),
      __typename: 'Merchant',
    },
  ]
}
export const getIsMerchantVisibility = (
  visibility: ReportVisibility | undefined,
): visibility is MerchantFieldsFragment => visibility?.__typename === 'Merchant'

export const mapToVisibility = (visibility?: ReportVisibility[]) =>
  visibility?.map((visibilityItem) =>
    getIsMerchantVisibility(visibilityItem)
      ? ORGANIZATION_ID
      : visibilityItem.id,
  ) || []

type Props = {
  owner: OwnerFieldsFragment | undefined
  user?: UserResource | null | undefined
  memberships: OrganizationMembershipResource[] | undefined
}

export const getReportOwnerName = ({ owner, user, memberships }: Props) => {
  const userId = user?.publicMetadata.dema_id
  const ownerDetails = memberships?.find(
    (member) => member.publicUserData.userId === owner?.externalId,
  )

  if (userId === owner?.id) return 'You'
  if (getIsTemplateReport(owner)) return 'Dema'

  const { firstName, lastName } = ownerDetails?.publicUserData ?? {}

  return `${firstName} ${lastName}`
}

type InputReport = ReportWithOwnerFieldsFragment[]

export const transformReport = (report: InputReport[number]): ReportResult => {
  return report as unknown as ReportResult
}

export const transformReports = (reports: InputReport) => {
  return reports as unknown as ReportResult[]
}
