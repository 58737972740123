import config from 'config'
import { Routes as Auth } from 'features/auth'
import { routes as customDashboardRoutes } from 'features/customDashboard/Routes'
import { routes as optimizationsRoutes } from 'features/optimizations/Routes'
import { routes as profileRoutes } from 'features/profile/Routes'
import { routes as reportsRoutes } from 'features/reports/Routes'
import { routes as settingsRoutes, teamRoutes } from 'features/settings/Routes'
import { lazy } from 'react'
import { createBrowserRouter } from 'react-router-dom'
import { AppWrapper } from './AppWrapper'
import { AuthenticatedOutlet } from './AuthenticatedOutlet'
import { NotFound } from './NotFound'
import { UnexpectedError } from './UnexpectedError'

export const router = createBrowserRouter(
  [
    {
      path: '*',
      children: [
        { path: 'auth/*', Component: Auth, errorElement: <UnexpectedError /> },
        {
          path: '*',
          Component: AuthenticatedOutlet,
          errorElement: <UnexpectedError />,
          children: [
            {
              Component: AppWrapper,
              children: [
                {
                  index: true,
                  Component: lazy(() => import('features/dashboard/Dashboard')),
                },
                {
                  path: 'optimizations',
                  Component: lazy(
                    () => import('features/optimizations/Optimizations'),
                  ),
                  children: optimizationsRoutes,
                },
                {
                  path: 'custom-dashboard',
                  Component: lazy(
                    () =>
                      import('features/customDashboard/CustomDashboardView'),
                  ),
                  children: customDashboardRoutes,
                },
                {
                  path: 'reports',
                  Component: lazy(() => import('features/reports/Reports')),
                  children: reportsRoutes,
                },
                {
                  path: 'settings',
                  Component: lazy(() => import('features/settings/Settings')),
                  children: [...settingsRoutes, ...teamRoutes],
                },
                {
                  path: 'profile',
                  Component: lazy(() => import('features/profile/Profile')),
                  children: profileRoutes,
                },
                { path: '*', Component: NotFound },
              ],
            },
          ],
        },
      ],
    },
  ],
  { basename: config.APP_BASENAME },
)
