import { Box, Flex, Image } from '@chakra-ui/react'
import { Typography } from 'components/Typography'
import { AnalyticsChartContainer } from 'features/reports/components/AnalyticsChart/AnalyticsChartContainer'
import { AnalyticsTable } from 'features/reports/components/AnalyticsTable/AnalyticsTable'
import { useDimensionsState } from 'features/reports/hooks/useDimensionsState'
import { useMetricsState } from 'features/reports/hooks/useMetricsState'

export const DataSection = () => {
  const { dimensions } = useDimensionsState()
  const { metrics } = useMetricsState()
  const isValidReport = dimensions.length > 0 && metrics.length > 0

  if (!isValidReport) {
    return (
      <Box p={10}>
        <Flex alignItems="center" pt={100} flexDir="column" gap={10}>
          <Image src="/images/EmptyData.svg" alt="No data" boxSize={200} />
          <Typography>You haven&apos;t added any data yet</Typography>
        </Flex>
      </Box>
    )
  }

  return (
    <>
      <AnalyticsChartContainer />
      <AnalyticsTable />
    </>
  )
}
