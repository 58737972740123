import { OrderedList, ListItem, Box } from '@chakra-ui/react'
import { Typography } from 'components/Typography'
import { useState, useEffect } from 'react'
import { CodeSnippet } from '../CodeSnippet'
import orderTrackingSnippet from './orderTrackingSnippet.txt'

export const OrderTracking = () => {
  const [fetchedSnippet, setFetchedSnippet] = useState('')

  useEffect(() => {
    fetch(orderTrackingSnippet)
      .then((r) => r.text())
      .then(setFetchedSnippet)
  }, [])

  return (
    <>
      <Typography fontSize="xl" lineHeight={8} color="grey.900" noOfLines={1}>
        Order tracking tag
      </Typography>
      <OrderedList my={4} spacing={2} px={2} maxW={800}>
        <ListItem>
          Log in to your GTM account, click on &quot;Tags&quot; in the left
          sidebar navigation and click the &quot;New&quot; button on top of the
          page.
        </ListItem>
        <ListItem>Name the tag &quot;Dema order tracking&quot;.</ListItem>
        <ListItem>
          Click on &quot;Custom HTML Tag&quot; and copy/paste the following code
          into the text field:
        </ListItem>
        <Box>
          <CodeSnippet snippet={fetchedSnippet} language="javascript" />
        </Box>
        <ListItem>
          Add the Data Layer Variables (DLVs). If you already have create DLVs,
          rename the values from the script above with the name of your
          variables. Otherwise, click on &quot;Variables&quot; in the left
          sidebar navigation and look for the &quot;User-Defined Variables&quot;
          section.
        </ListItem>
        <ListItem>
          Click the &quot;New&quot; button and add one DLV for each of the
          values within double bracers &#123;&#123;
          <Typography as="span" color="sky.600">
            &lt;variable name&gt;
          </Typography>
          &#125;&#125; in the script above.
        </ListItem>
      </OrderedList>
    </>
  )
}
