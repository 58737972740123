import { useMutation } from '@apollo/client'
import { getApolloClient } from 'apollo'
import { graphql } from 'generated/graphql'
import { type AnalyticsConfigInput } from 'generated/graphql/graphql'
import { debounce, omit } from 'lodash-es'
import { useCallback, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { type OptionalKey } from 'types/optionalKey'
import { v7 as uuidv7 } from 'uuid'
import { AnalyticsConfigFragment } from './fragments'
import { type AnalyticsConfig } from './types'

const CREATE_ANALYTICS_CONFIG_MUTATION = graphql(/* GraphQL */ `
  mutation CreateAnalyticsConfig($params: AnalyticsConfigInput!) {
    createAnalyticsConfig(params: $params) {
      ...AnalyticsConfigFields
    }
  }
`)

export const ANALYTICS_CONFIG_SEARCH_PARAM_KEY = 'state'

// Map of cache keys to config UUIDs. The cache reduces the number of analytics config created
const configIdMap = new Map<string, string>()
const generateCacheKey = (
  config: Omit<AnalyticsConfig, 'id' | '__typename'>,
) => {
  const sortedKeys = Object.keys(config).sort()

  return sortedKeys
    .map(
      (key) => `${key}:${JSON.stringify(config[key as keyof typeof config])}`,
    )
    .join('|')
}
// Longer debounce gives time for the user to change the state several times before dispatching a url update.
const debounceTime = 2000

export const useCreateAnalyticsConfig = ({
  shouldSetUrl = true,
}: { shouldSetUrl?: boolean } = {}): [
  typeof createAnalyticsConfig,
  typeof state,
] => {
  const [mutation, state] = useMutation(CREATE_ANALYTICS_CONFIG_MUTATION)
  const [, setSearchParams] = useSearchParams()
  const latestConfigIdRef = useRef<string | null>(null)

  // Debouncing the search params update to avoid visual glitches when the user changes the state quickly
  const debouncedSetSearchParams = useCallback(
    (configId: string) => {
      const setParams = debounce(() => {
        // Skip updates if the config id has changed since the debounce was triggered
        if (configId === latestConfigIdRef.current) {
          setSearchParams({
            [ANALYTICS_CONFIG_SEARCH_PARAM_KEY]: configId,
          })
        }
      }, debounceTime)

      setParams()
    },
    [setSearchParams],
  )

  const createAnalyticsConfig = useCallback(
    async (
      analyticsConfigInput: OptionalKey<AnalyticsConfig, 'id' | '__typename'>,
    ) => {
      const cacheKey = generateCacheKey(
        omit(analyticsConfigInput, ['__typename', 'id']),
      )
      const cachedConfigId = configIdMap.get(cacheKey)

      if (cachedConfigId) {
        if (shouldSetUrl) {
          latestConfigIdRef.current = cachedConfigId
          debouncedSetSearchParams(cachedConfigId)
        }

        const apolloClient = getApolloClient()
        const cachedConfig = apolloClient.readFragment({
          id: apolloClient.cache.identify({
            id: cachedConfigId,
            __typename: 'AnalyticsConfig',
          }),
          fragment: AnalyticsConfigFragment,
          fragmentName: 'AnalyticsConfigFields',
        })

        if (!cachedConfig) {
          return undefined
        }

        return cachedConfig as unknown as AnalyticsConfig
      }

      const id = uuidv7()
      const inputWithId = {
        ...analyticsConfigInput,
        id,
      }

      configIdMap.set(cacheKey, id)

      const createMutation = await mutation({
        variables: {
          params: omit(inputWithId, [
            '__typename',
          ]) as unknown as AnalyticsConfigInput,
        },
        onCompleted: (data) => {
          if (!data.createAnalyticsConfig) return
          if (shouldSetUrl) {
            const newConfigId = data.createAnalyticsConfig.id

            latestConfigIdRef.current = newConfigId
            debouncedSetSearchParams(newConfigId)
          }
        },
        onError: () => {
          configIdMap.delete(cacheKey)
        },
      })

      const result = createMutation.data?.createAnalyticsConfig as unknown as
        | AnalyticsConfig
        | undefined

      if (!result) throw new Error(createMutation.errors?.[0].message)

      return result
    },
    [mutation, shouldSetUrl, debouncedSetSearchParams],
  )

  return [createAnalyticsConfig, state]
}
