import {
  Box,
  type PlacementWithLogical,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from '@chakra-ui/react'
import { Button } from 'components/buttons'

import { Typography } from 'components/Typography'
import { addYears, format, startOfYear, subYears } from 'date-fns'
import { useEffect, type FC } from 'react'
import DatePicker from 'react-datepicker'
import type { FieldError } from 'react-hook-form'
import { DATE_FORMAT } from '../consts'
import { CustomDay } from '../CustomDay'
import CustomHeader from '../CustomHeader'
import '../datepicker.scss'

type Props = {
  error?: FieldError
  label?: string
  placeholder?: string
  selectedDate: Date | null
  setSelectedDate: (date: Date | null) => void
  placement?: PlacementWithLogical
  fullWidth?: boolean
}

const startOfCurrentYear = startOfYear(new Date())
const minDate = subYears(startOfCurrentYear, 4)
const maxDate = addYears(startOfCurrentYear, 4)

export const SingleDatepicker: FC<Props> = ({
  error,
  label,
  placeholder,
  selectedDate,
  placement = 'bottom',
  fullWidth = false,
  setSelectedDate,
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure()

  useEffect(() => {
    if (selectedDate) {
      onClose()
    }
  }, [selectedDate, onClose])

  return (
    <Box w={fullWidth ? '100%' : 'auto'}>
      {label && (
        <Typography fontSize="xs" mb={2}>
          {label}
        </Typography>
      )}

      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        placement={placement}
        size="small"
        isLazy
      >
        <PopoverTrigger>
          <Button
            leadingIcon={{ name: 'CalendarDayIcon' }}
            variant="outline"
            colorScheme="grey"
            minWidth="100%"
            justifyContent="flex-start"
            borderColor={error ? 'red.500' : undefined}
            fontWeight={400}
          >
            {selectedDate
              ? format(selectedDate, DATE_FORMAT)
              : (placeholder ?? '')}
          </Button>
        </PopoverTrigger>

        {/* Portal is needed for click outside to close popover to work */}
        <Portal>
          <PopoverContent width="unset">
            <Box className="datepicker-container single-datepicker">
              <DatePicker
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                renderCustomHeader={(params) =>
                  CustomHeader({ ...params, minDate, maxDate })
                }
                renderDayContents={CustomDay}
                calendarStartDay={1}
                minDate={minDate}
                maxDate={maxDate}
                inline
              />
            </Box>
          </PopoverContent>
        </Portal>
      </Popover>

      {error && (
        <Typography fontSize="xs" color={error ? 'red.500' : undefined}>
          {error.message}
        </Typography>
      )}
    </Box>
  )
}
