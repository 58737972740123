import { Divider } from '@chakra-ui/react'
import { BaseView } from '../shared/BaseView'
import { OrderTracking } from './OrderTracking/OrderTracking'
import { PageViewTracking } from './PageViewTracking/PageViewTracking'

export const TrackerView = () => {
  return (
    <BaseView
      title="Tracker"
      description="Use the instructions below to set up your trackers and capture your data to Dema."
    >
      <Divider my={6} />
      <OrderTracking />
      <Divider my={6} />
      <PageViewTracking />
    </BaseView>
  )
}
