import { useQuery } from '@apollo/client'
import { useDisclosure } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { graphql } from 'generated/graphql'
import { useFeatureFlags } from 'graphql/useFeatureFlags'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { BaseView } from '../shared/BaseView'
import { AccountsTable } from './AccountsTable/AccountsTable'
import { type AdsMatchingRule } from './AccountsTable/types'
import { AddModal } from './AddModal/AddModal'
import { useSelectAccounts } from './hooks/useSelectAccounts'
import { SelectAccountsModal } from './SelectAccountsModal'

const INTEGRATIONS_VIEW_QUERY = graphql(/* GraphQL */ `
  query IntegrationsViewQuery($merchantSiteParams: MerchantSiteInput!) {
    viewer {
      id
      merchantSite(params: $merchantSiteParams) {
        frontendId
        ...Integrations_MerchantSite
      }
    }
  }
`)

export const IntegrationsView = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { merchantId = '', frontendId } = useMerchantInfo()
  const flags = useFeatureFlags()
  const { data, loading } = useQuery(INTEGRATIONS_VIEW_QUERY, {
    variables: { merchantSiteParams: { frontendId: frontendId ?? '' } },
  })

  const merchantSite = data?.viewer?.merchantSite
  const isReadOnly = !flags.allowSettingsWrite

  const {
    isSelectAccountsModalOpen,
    closeSelectAccountsModal,
    connectCallback,
    connectedIntegration,
  } = useSelectAccounts({ merchantId, merchantSite })

  return (
    <BaseView
      title="Integrations"
      description="Manage your integrations with ad platforms and e-commerce systems"
      buttonsContent={
        isReadOnly ? undefined : (
          <Button
            colorScheme="primary"
            variant="solid"
            size="sm"
            onClick={onOpen}
          >
            Add data connection
          </Button>
        )
      }
    >
      <AddModal isOpen={isOpen} onClose={onClose} />
      <AccountsTable
        isLoading={loading}
        merchantSite={merchantSite}
        isReadOnly={isReadOnly}
      />
      <SelectAccountsModal
        isOpen={isSelectAccountsModalOpen}
        onClose={closeSelectAccountsModal}
        accounts={
          connectedIntegration?.costConfig
            ?.adsMatchingRules as AdsMatchingRule[]
        }
        connectCallback={connectCallback}
      />
    </BaseView>
  )
}
