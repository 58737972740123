import {
  ClerkLoaded,
  ClerkLoading,
  SignedIn,
  SignedOut,
  useAuth,
  useOrganization,
  useUser,
} from '@clerk/clerk-react'
import { Provider } from 'jotai'
import { Suspense, useEffect, useRef } from 'react'
import {
  Outlet,
  createSearchParams,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { InitializeAppData } from 'shared/HydrateApp'
import { AuthedIntercomProvider } from 'shared/IntercomProvider/IntercomProvider'
import { LoaderPage } from 'shared/LoaderPage'
import { getStore } from 'shared/store'
import { identifyUser } from 'utils/analytics'
import { setSentryUser } from 'utils/sentry'

const AuthComponent = () => {
  const hasRunTrackEvent = useRef(false)
  const { isLoaded: isAuthLoaded } = useAuth()
  const { user } = useUser()
  const { organization } = useOrganization()

  // only run once
  if (organization && user && !hasRunTrackEvent.current) {
    hasRunTrackEvent.current = true
    const { name: merchantName, publicMetadata: orgMetadata } =
      organization ?? {}
    const { primaryEmailAddress, publicMetadata: userMetadata } = user
    const orgDemaId = String(orgMetadata.dema_id)
    const userDemaId = String(userMetadata.dema_id)

    identifyUser(userDemaId, {
      merchantId: orgDemaId,
      email: primaryEmailAddress?.emailAddress,
      merchantName: merchantName,
    })
    setSentryUser({
      demaInternalUserId: userDemaId,
      email: primaryEmailAddress?.emailAddress,
      merchantId: orgDemaId,
      merchantName,
    })
  }

  return isAuthLoaded && user && organization ? (
    <AuthedIntercomProvider>
      <InitializeAppData>
        <Outlet />
      </InitializeAppData>
    </AuthedIntercomProvider>
  ) : (
    <LoaderPage />
  )
}

const RedirectToSignIn = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { isLoaded, isSignedIn } = useUser()

  useEffect(() => {
    if (isLoaded && !isSignedIn) {
      navigate(
        {
          pathname: '/auth/login',
          search: createSearchParams({
            ...(location.search ? { q: location.search } : {}),
            from: location.pathname,
          }).toString(),
        },
        { replace: true },
      )
    }
  }, [isLoaded, isSignedIn, location.pathname, location.search, navigate])

  return <LoaderPage />
}

// This component ensures we have a valid token before rendering the app
export const AuthenticatedOutlet = () => {
  const { user } = useUser()

  return (
    <>
      <ClerkLoading>
        <LoaderPage />
      </ClerkLoading>
      <ClerkLoaded>
        <SignedIn>
          {/* Using a store allows us to update atoms outside of React's lifecycle which allows performance gains. */}
          <Provider key={user?.id} store={getStore()}>
            <Suspense fallback={<LoaderPage />}>
              <AuthComponent />
            </Suspense>
          </Provider>
        </SignedIn>
        <SignedOut>
          <RedirectToSignIn />
        </SignedOut>
      </ClerkLoaded>
    </>
  )
}
