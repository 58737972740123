import { useAtomValue, useSetAtom } from 'jotai'
import { focusAtom } from 'jotai-optics'
import { newReportAtom, reportStateAtom } from '../atoms/reportViewStateAtom'

export const useReportStateAtom = () => useAtomValue(reportStateAtom)
export const useSetReportStateAtom = () => useSetAtom(reportStateAtom)

export const reportNameAtom = focusAtom(reportStateAtom, (optic) =>
  optic.prop('name'),
)
export const reportDescriptionAtom = focusAtom(reportStateAtom, (optic) =>
  optic.prop('description'),
)
export const reportIdAtom = focusAtom(reportStateAtom, (optic) =>
  optic.prop('id'),
)
export const reportOwnerAtom = focusAtom(reportStateAtom, (optic) =>
  optic.prop('owner'),
)
export const reportVisibilityAtom = focusAtom(reportStateAtom, (optic) =>
  optic.prop('visibility'),
)
export const reportLabelsAtom = focusAtom(reportStateAtom, (optic) =>
  optic.prop('labels'),
)
export const reportCreatedAtAtom = focusAtom(reportStateAtom, (optic) =>
  optic.prop('createdAt'),
)
export const reportUpdatedAtAtom = focusAtom(reportStateAtom, (optic) =>
  optic.prop('updatedAt'),
)

export const useNewReportState = () => useAtomValue(newReportAtom)
