import { useMutation, type ApolloCache } from '@apollo/client'
import { graphql } from 'generated/graphql'
import {
  type ReportLabel,
  type ReportWithOwnerFieldsFragment,
  type Team,
} from 'generated/graphql/graphql'
import { useTrackEvent } from 'graphql/events/useTrackEvent'
import { cloneDeep, set } from 'lodash-es'
import { report } from 'mocks/data/report'
import { useCallback } from 'react'
import { type ReportResult } from './types'
import { MERCHANT_REPORTS_QUERY } from './useMerchantReports'
import { USER_REPORTS_QUERY } from './useUserReports'
import {
  getIsMerchantVisibility,
  mapToVisibility,
  transformReport,
} from './utils'

const CREATE_REPORT_MUTATION = graphql(/* GraphQL */ `
  mutation CreateReportMutation($payload: JSONObject!) {
    createReport(payload: $payload) {
      ...ReportWithOwnerFields
    }
  }
`)

export type CreateReportParams = {
  report: Partial<ReportResult>
}

export const useCreateReport = (): [typeof createReport, typeof state] => {
  const [mutation, state] = useMutation(CREATE_REPORT_MUTATION)
  const [trackEvent] = useTrackEvent()

  const createReport = useCallback(
    async ({ report }: CreateReportParams): Promise<ReportResult> => {
      const labelIds = report.labels?.map((label) => label.id) ?? []

      const createMutation = await mutation({
        variables: {
          payload: {
            ...report,
            analyticsConfigId: report.analyticsConfig?.id,
            teams: mapToVisibility(report.visibility),
            labels: labelIds,
          },
        },
        optimisticResponse: {
          createReport: {
            ...report,
            id: 'optimistic',
            __typename: 'Report',
            updatedAt: new Date().toISOString(),
          } as unknown as ReportWithOwnerFieldsFragment,
        },
        update: (cache, { data }) => {
          if (!data?.createReport) return

          updateMerchantReportsQuery({ cache, report: data.createReport })
          // Update team reports
          updateTeamReportsQueries({ cache, report: data.createReport })
          // Update report labels
          updateReportLabelsQuery({ cache, report: data.createReport })

          updateUserReportsQuery({ cache, report: data.createReport })
        },
        onCompleted: ({ createReport }) => {
          if (!createReport) return

          trackEvent({
            eventName: 'Report Created',
            eventProperties: {
              reportId: createReport.id,
              reportName: createReport.name,
              reportDescription: createReport.description,
            },
          })
        },
      })

      const result = createMutation.data?.createReport

      if (!result) throw new Error(createMutation.errors?.[0].message)

      return transformReport(result)
    },
    [mutation, trackEvent],
  )

  return [createReport, state]
}

const updateMerchantReportsQuery = ({
  cache,
  report,
}: {
  cache: ApolloCache<unknown>
  report: ReportWithOwnerFieldsFragment
}) => {
  const isOrganizationReport = getIsMerchantVisibility(report.visibility[0])

  if (isOrganizationReport) {
    cache.updateQuery({ query: MERCHANT_REPORTS_QUERY }, (queryData) => {
      if (!queryData?.viewer) return queryData

      const { reports } = queryData.viewer.merchant
      const clonedQueryData = cloneDeep(queryData)

      set(clonedQueryData, 'viewer.merchant.reports', [...reports, report])

      return clonedQueryData
    })
  }
}

const updateTeamReportsQueries = ({
  cache,
  report,
}: {
  cache: ApolloCache<unknown>
  report: ReportWithOwnerFieldsFragment
}) => {
  const isOrganizationReport = getIsMerchantVisibility(report.visibility[0])
  const reportCacheId = cache.identify(report)

  if (!isOrganizationReport) {
    // Go through each team and update the reports
    for (const team of report.visibility) {
      cache.modify<Team>({
        id: cache.identify(team),
        fields: {
          reports(existingReportRefs = [], { toReference }) {
            const newRef = toReference(reportCacheId ?? '')

            if (newRef) {
              return [...existingReportRefs, newRef]
            }

            return existingReportRefs
          },
        },
      })
    }
  }
}

const updateReportLabelsQuery = ({
  cache,
}: {
  cache: ApolloCache<unknown>
  report: ReportWithOwnerFieldsFragment
}) => {
  for (const label of report.labels) {
    cache.modify<ReportLabel>({
      id: cache.identify(label),
      fields: {
        reportCount(previousValue = 0) {
          return previousValue + 1
        },
      },
    })
  }
}

const updateUserReportsQuery = ({
  cache,
  report,
}: {
  cache: ApolloCache<unknown>
  report: ReportWithOwnerFieldsFragment
}) => {
  cache.updateQuery({ query: USER_REPORTS_QUERY }, (queryData) => {
    if (!queryData?.viewer) return queryData

    const queryDataClone = cloneDeep(queryData)

    set(queryDataClone, 'viewer.reports', [...queryData.viewer.reports, report])

    return queryDataClone
  })
}
