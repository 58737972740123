import { Button, Flex } from '@chakra-ui/react'
import { useUser } from '@clerk/clerk-react'
import { Typography } from 'components/Typography'
import {
  reportDescriptionAtom,
  reportNameAtom,
  useReportStateAtom,
} from 'features/reports/hooks/useReportState'
import { useAtomValue } from 'jotai'
import { useState, type Dispatch, type FC, type SetStateAction } from 'react'
import { ReportFavoriteStar } from '../ReportFavoriteStar'
import { ReportContextualMenu } from '../ReportSidebar/ReportContextualMenu/ReportContextualMenu'
import { DownloadCSVButton } from './DownloadCSVButton'
import { ReportHeaderWrapper } from './ReportHeaderWrapper'
import { ReportNameDescription } from './ReportNameDescription'
import { ReportPanelControl } from './ReportPanelControl'
import { SaveChangesButtons } from './SaveChangesButtons'

interface EditReportHeaderProps {
  setIsEditing: Dispatch<SetStateAction<boolean>>
}

const EditReportHeader: FC<EditReportHeaderProps> = ({ setIsEditing }) => {
  const reportName = useAtomValue(reportNameAtom)
  const reportDescription = useAtomValue(reportDescriptionAtom)
  const [draftNameDescription, setDraftNameDescription] = useState({
    name: reportName,
    description: reportDescription,
  })

  return (
    <>
      <ReportNameDescription
        reportState={draftNameDescription}
        setReportState={setDraftNameDescription}
      />
      <Flex gap={2} alignItems="center">
        <Button
          size="sm"
          colorScheme="gray"
          variant="outline"
          onClick={() => setIsEditing(false)}
        >
          Cancel
        </Button>
        <SaveChangesButtons
          reportName={draftNameDescription?.name}
          reportDescription={draftNameDescription?.description}
          onCompleted={() => setIsEditing(false)}
        />
        <ReportPanelControl />
      </Flex>
    </>
  )
}

interface NonEditReportHeaderProps {
  setIsEditing: Dispatch<SetStateAction<boolean>>
}

const NonEditReportHeader: FC<NonEditReportHeaderProps> = ({
  setIsEditing,
}) => {
  const report = useReportStateAtom()
  const { user } = useUser()

  return (
    <>
      <Flex gap={2} alignItems="center">
        <Typography fontSize="lg" fontWeight={500} lineHeight={7}>
          {report.name}
        </Typography>
        <ReportFavoriteStar size="sm" report={report} />
        <ReportContextualMenu
          report={report}
          isEditable={true}
          userId={user?.publicMetadata.dema_id?.toString()}
          setIsEditing={setIsEditing}
        />
      </Flex>
      <Flex gap={2} alignItems="center">
        <DownloadCSVButton />
        <SaveChangesButtons />
        <ReportPanelControl />
      </Flex>
    </>
  )
}

export const ReportHeader: FC = () => {
  const [isEditing, setIsEditing] = useState(false)

  return (
    <ReportHeaderWrapper>
      {isEditing ? (
        <EditReportHeader setIsEditing={setIsEditing} />
      ) : (
        <NonEditReportHeader setIsEditing={setIsEditing} />
      )}
    </ReportHeaderWrapper>
  )
}
