import { type ChartState } from 'graphql/reports/types'
import { useCreateAnalyticsConfig } from 'graphql/reports/useCreateAnalyticsConfig'
import { atom, useAtomValue, useSetAtom, type SetStateAction } from 'jotai'
import { focusAtom } from 'jotai-optics'
import { useCallback } from 'react'
import { getStore } from 'shared/store'
import { CHART_TYPE_ID } from 'utils/chart/chartTypes'
import { analyticsConfigAtom } from '../atoms/reportViewStateAtom'
import {
  getAnalyticsConfigFromStore,
  getDefaultGColor,
  getDefaultSeries,
  getDefaultXAxis,
} from '../utils/utils'
import { dateStateAtom } from './useDateState'
import { dimensionsStateAtom } from './useDimensionsState'
import { useIsNewReportFlow } from './useIsNewReportFlow'
import { metricsStateAtom } from './useMetricsState'

const focusChartStateAtom = focusAtom(analyticsConfigAtom, (optic) =>
  optic.prop('chart'),
)

export const chartStateAtom = atom(
  (get) => {
    const { xAxis, color, series } = get(focusChartStateAtom)
    const { metricKeys } = get(metricsStateAtom)
    const { dimensionKeys } = get(dimensionsStateAtom)
    const { isCompare } = get(dateStateAtom)

    const validSeries = getDefaultSeries(series, metricKeys)

    const isScatterPlot =
      validSeries.length === 1 && validSeries[0].type === CHART_TYPE_ID.SCATTER

    const validXAxis = getDefaultXAxis(
      xAxis,
      isScatterPlot,
      dimensionKeys,
      metricKeys,
    )

    const validGroup = getDefaultGColor(
      color,
      dimensionKeys,
      validXAxis,
      isCompare,
      validSeries.length > 1,
    )

    return {
      xAxis: validXAxis,
      color: validGroup,
      series: validSeries,
    }
  },
  (_, set, chartState: SetStateAction<ChartState>) => {
    set(focusChartStateAtom, chartState)
  },
)

export const useChartState = () => useAtomValue(chartStateAtom)

export const useSetChartState = () => {
  const setChartStateAtom = useSetAtom(chartStateAtom)
  const [createAnalyticsConfig] = useCreateAnalyticsConfig()
  const isNewReportFlow = useIsNewReportFlow()

  const setChartState = useCallback(
    (payload: SetStateAction<ChartState>) => {
      setChartStateAtom(payload)
      if (!isNewReportFlow) {
        const newAnalyticsConfig = getAnalyticsConfigFromStore()
        const currentChartState = getStore().get(chartStateAtom)

        if (typeof payload === 'function') {
          newAnalyticsConfig.chart = payload(currentChartState)
        } else {
          newAnalyticsConfig.chart = payload
        }

        createAnalyticsConfig(newAnalyticsConfig)
      }
    },
    [createAnalyticsConfig, isNewReportFlow, setChartStateAtom],
  )

  return setChartState
}
