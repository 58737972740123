import { type IconName } from 'components/Icon/Icon'
import { Navigate, type RouteObject } from 'react-router-dom'
import { IntegrationsView } from './views/IntegrationsView/IntegrationsView'
import { FulfilmentCostsView } from './views/LogisticsCosts/FulfilmentCostsView'
import { ReturnCostsView } from './views/LogisticsCosts/ReturnCostsView'
import { ReturnPercentView } from './views/LogisticsCosts/ReturnPercentView'
import { MappingsView } from './views/MappingsView/MappingsView'
import { NoOfUnhandledMappings } from './views/MappingsView/UnhandledMappings/NoOfUnhandledMappings'
import { MarketingCostsView } from './views/MarketingCostsView/MarketingCostsView'
import { OrganisationGeneralView } from './views/OrganisationViews/OrganisationGeneralView/OrganisationGeneralView'
import { OrganisationLabelsView } from './views/OrganisationViews/OrganisationLabelsView/OrganisationLabelsView'
import { OrganisationMembersView } from './views/OrganisationViews/OrganisationMembersView/OrganisationMembersView'
import { ProductView } from './views/ProductView'
import { CreateNewTeamView } from './views/Teams/CreateNewTeamView/CreateNewTeamView'
import { TeamDataView } from './views/Teams/TeamDataView/TeamDataView'
import { TeamGeneralView } from './views/Teams/TeamGeneralView/TeamGeneralView'
import { TeamMembersView } from './views/Teams/TeamMembersView/TeamMembersView'
import { TrackerView } from './views/TrackerView/TrackerView'

export const routes = [
  {
    index: true,
    element: <Navigate to="data/logistic/fulfilment" replace />,
  },
  {
    path: 'data',
    handle: {
      label: 'Data',
      iconName: 'DatabaseIcon' satisfies IconName,
    },
    children: [
      {
        path: 'logistic',
        handle: {
          label: 'Logistics costs',
          iconName: 'ShippingTruckIcon' satisfies IconName,
        },
        children: [
          {
            path: 'fulfilment',
            handle: {
              label: 'Fulfilment costs',
              iconName: 'ListIcon' satisfies IconName,
            },
            element: <FulfilmentCostsView />,
          },
          {
            path: 'return',
            handle: {
              label: 'Return costs',
              iconName: 'ReturnIcon' satisfies IconName,
            },
            element: <ReturnCostsView />,
          },
          {
            path: 'return-percentage',
            handle: {
              label: 'Return percentage',
              iconName: 'Percent1Icon' satisfies IconName,
            },
            element: <ReturnPercentView />,
          },
        ],
      },
      {
        path: 'product',
        handle: {
          label: 'Product costs',
          iconName: 'ShoppingBagIcon' satisfies IconName,
        },
        element: <ProductView />,
      },
      {
        path: 'mapping',
        handle: {
          label: 'Mapping',
          iconName: 'ShuffleIcon' satisfies IconName,
          rightItem: <NoOfUnhandledMappings />,
        },
        element: <MappingsView />,
      },
      {
        path: 'tracker',
        handle: {
          label: 'Tracker',
          isAdminOnly: true,
          iconName: 'MapPinIcon' satisfies IconName,
        },
        element: <TrackerView />,
      },
      {
        path: 'integration',
        handle: {
          label: 'Integrations',
          iconName: 'IntegrationsIcon' satisfies IconName,
        },
        element: <IntegrationsView />,
      },
      {
        path: 'marketing',
        handle: {
          label: 'Marketing costs',
          iconName: 'MegaphoneIcon' satisfies IconName,
        },
        element: <MarketingCostsView />,
      },
    ],
  },
  {
    path: 'organisation',
    handle: {
      label: 'Organisation',
      iconName: 'OfficeIcon' satisfies IconName,
    },
    children: [
      {
        path: 'general',
        handle: {
          label: 'General',
          iconName: 'HomeGearIcon' satisfies IconName,
        },
        element: <OrganisationGeneralView />,
      },
      {
        path: 'members',
        handle: {
          label: 'Members',
          iconName: 'UserMultipleIcon' satisfies IconName,
        },
        element: <OrganisationMembersView />,
      },
      {
        path: 'labels',
        handle: {
          label: 'Labels',
          iconName: 'TagIcon' satisfies IconName,
        },
        element: <OrganisationLabelsView />,
      },
    ],
  },
] satisfies RouteObject[]

export const teamRoutes = [
  {
    path: 'teams',
    children: [
      {
        path: 'new',
        element: <CreateNewTeamView />,
      },
      {
        path: ':teamId',
        children: [
          {
            path: 'general',
            element: <TeamGeneralView />,
          },
          {
            path: 'data',
            element: <TeamDataView />,
          },
          {
            path: 'members',
            element: <TeamMembersView />,
          },
        ],
      },
    ],
  },
] satisfies RouteObject[]
