import { useQuery } from '@apollo/client'
import { useDisclosure } from '@chakra-ui/react'
import { Button } from 'components/buttons'
import { graphql } from 'generated/graphql'
import { useFeatureFlags } from 'graphql/useFeatureFlags'
import { useMerchantInfo } from 'graphql/useMerchantInfo'
import { BaseView } from '../shared/BaseView'
import { AddMarketingCostSidebar } from './AddMarketingCostSidebar/AddMarketingCostSidebar'
import { MarketingCostTable } from './MarketingCostTable/MarketingCostTable'

const MARKETING_COST_VIEW_QUERY = graphql(/* GraphQL */ `
  query MarketingCostsViewQuery($merchantSiteParams: MerchantSiteInput!) {
    viewer {
      id
      merchantSite(params: $merchantSiteParams) {
        frontendId
        ...MarketingCostManualInputs_MerchantSite
      }
    }
  }
`)

export const MarketingCostsView = () => {
  const { onOpen, isOpen, onClose } = useDisclosure()
  const flags = useFeatureFlags()
  const { frontendId } = useMerchantInfo()

  const { data } = useQuery(MARKETING_COST_VIEW_QUERY, {
    variables: { merchantSiteParams: { frontendId: frontendId ?? '' } },
  })
  const isReadOnly = !flags.allowSettingsWrite

  return (
    <>
      <BaseView
        title="Marketing costs"
        description="Input manual marketing costs in addition to the costs that are fetched from your integrated ad platforms"
        buttonsContent={
          isReadOnly ? undefined : (
            <Button size="sm" onClick={onOpen}>
              Add marketing costs
            </Button>
          )
        }
      >
        <MarketingCostTable
          merchantSite={data?.viewer?.merchantSite}
          isReadOnly={isReadOnly}
        />
      </BaseView>
      <AddMarketingCostSidebar isOpen={isOpen} onClose={onClose} />
    </>
  )
}
